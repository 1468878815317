.ant-table-pagination.ant-pagination {
    padding: 0px 12px !important;
}

.always-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.always-scrollbar::-webkit-scrollbar:vertical {
    width: 6px;
}

.always-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
}
